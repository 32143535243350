// api接口
export let baseUrl = 'https://scrm-api.xilianmiao.com/'
// h5链接
export let h5Url = 'https://scrm-guide.xilianmiao.com/'
// cdn链接
export let cdnUrl = 'https://xilianmao-1255955238.cos.ap-guangzhou.myqcloud.com/applet/'
// #ifdef MP-WEIXIN
const { miniProgram: { envVersion } } = uni.getAccountInfoSync()

console.log('当前环境', envVersion)
switch (envVersion) {
	// 线上环境
	case 'release':
		baseUrl = 'https://scrm-api.xilianmiao.com/'
		h5Url = 'https://scrm-guide.xilianmiao.com/'
		break
	// 体验环境、本地环境
	default:
		//测试
		baseUrl = 'https://scrm-api.uat.xilianmiao.com/'
		// baseUrl = 'http://192.168.72.5:90/'
		h5Url = 'https://scrm-api.uat.xilianmiao.com/'
		cdnUrl = 'https://xilianmao-1255955238.cos.ap-guangzhou.myqcloud.com/applet/'
}
// #endif

// #ifdef H5
switch (process.env.APP_ENV) {
	// 线上环境
	case 'H5-prod':
		baseUrl = 'https://scrm-api.xilianmiao.com/'
		h5Url = 'https://scrm-guide.xilianmiao.com/'
		break
	// 体验环境、本地环境
	default:
		// 测试
		baseUrl = 'https://scrm-api.uat.xilianmiao.com/'
		h5Url = 'https://scrm-api.uat.xilianmiao.com/'
		cdnUrl = 'https://xilianmao-1255955238.cos.ap-guangzhou.myqcloud.com/applet/'
}
// #endif